<template>
 <CCol>
  <CRow v-show="loading">
    <CCol col="12">
      <CCard v-show="loading">
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
        </CCardBody>
      </CCard>
    </CCol>    
  </CRow>
  <CRow v-show="!loading">
    <CToaster :autohide="3000">
      <template>              
        <div v-if="reportSaved">
          <CToast          
            :show="reportSaved"
            color="success"
          >
            Report saved!
          </CToast>
        </div>
      </template>
      <template>        
        <div v-if="actionItemSaved">
          <CToast          
            :show="actionItemSaved"
            color="success"
          >
            Action Item saved!
          </CToast>
        </div>
      </template>      
      <template>         
        <div v-if="reportGenerateMsg">
          <CToast          
            :show="reportGenerateMsg"
            color="warning"
          >
          Generating the report will take a while....
          </CToast>
        </div>
      </template>
      <template>             
        <div v-if="reportFailedMsg">
          <CToast          
            :show="reportFailedMsg"
            color="danger"
          >
          Generating the report failed!                
          </CToast>
        </div>
      </template>
      <template>              
        <div v-if="reportSaveFailedMsg">      
          <CToast          
            :key="1"
            :show="reportSaveFailedMsg"
            color="danger"
          >
          Saving report {{ report.report_name }} failed...
          </CToast>
        </div>
      </template>            
    </CToaster>      
    <CCol col="12">
      <CCard>
        <CCardBody>
          <CButton color="light" class="float-left  col-sm-2 col-xl-1" @click="goBack">Back</CButton>
          <CButton v-on:click="saveReport();" color="success" class="ml-5 float-left col-sm-2 col-xl-1" :disabled="saveDisabled" >Save Report</CButton>
        </CCardBody>
      </CCard>
    </CCol>    
    <CCol xs="12" sm="12" md="12" lg="4" xl="4">      
      <CCard>
        <CCardHeader>
          <b>Report Master Data</b>
        </CCardHeader>
        <CCardBody>
          <CForm>
            <template>
              <CRow>    
                  <CCol col="6">                         
                    <CInput
                      label="Report Name"
                      placeholder="Report Name"
                      v-model="report.report_name"
                      valid-feedback="Report name is valid."
                      invalid-feedback="Minimum length is 4 and only characters and spaces."
                      :is-valid="reportNameValidator"
                      @input="inputChanged()" 
                    >
                      <template #prepend-content><CIcon name="cil-user"/></template>
                    </CInput>       
                  </CCol>
                  <CCol col="6">                         
                    <CInput
                      label="Customer Name"
                      placeholder="Customer Name"
                      autocomplete="username"
                      description="The Customer can't be changed."
                      :value="selectedCustomer.customer_name"
                      disabled
                    >
                      <template #prepend-content><CIcon name="cil-user"/></template>
                    </CInput>
                  </CCol>
              </CRow>
            </template>
            <template>
              <CRow alignVertical="center" >
                <CCol col="3" >      
                  <b>Workload</b>
                </CCol>                      
                <CCol col="9">                      
                  <multiselect
                    v-model="selectedWorkload"
                    :options="workloads"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="true"
                    :loading="workloadsLoading"
                    :multiple="false"
                    label="label" 
                    :hideSelected="false"
                    placeholder="Pick a workload"
                    @input="inputChanged()">
                  </multiselect>
                </CCol>                    
              </CRow>
            </template>                            
          </CForm>    
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <b>Remediation Items</b>
        </CCardHeader>
        <CCardBody> 
          <CRow>        
            <CCol col="12">
              <p>
                <multiselect
                  :value="selectedRemediationItems"
                  :options="remediationItems"
                  track-by="id"
                  :searchable="true"
                  :close-on-select="false"
                  :multiple="true"                    
                  label="name" 
                  :hideSelected="true"
                  :loading="remediationItemsLoading"
                  @select="selectRemediationItem"
                  @remove="removeRemediationItem"
                  @input="inputChanged()" 
                  group-label="lense" 
                  group-values="data" 
                  :group-select="true" 
                />
              </p>                    
            </CCol>
          </CRow>
          <hr>
          <CRow>        
            <CCol col="12">
              <CInput 
                label="Total Effort"
                placeholder="0"
                v-model="reportTotalEffort"
                :horizontal="{ label: 'col-sm-4 col-xl-2', input: 'col-sm-4 col-xl-3'}"
                disabled>
                  <template #prepend-content><CIcon name="cil-av-timer"/></template>
              </CInput>
            </CCol>
            <CCol col="5">
              <p >
                <CInputCheckbox
                  label="Hide Effort Estimation in Report"
                  name="Hide Effort Estimation in Report"
                  :disabled="true"
                  v-c-tooltip="{content: 'Currently not supported.', placement: 'left'}"
                />
              </p>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>            
      <CCard>
        <CCardHeader>
          <CRow>                   
            <CCol col="11" >  
                <b>Recommendations - Customer Action Items</b><br>
                <i>Add up to three custom recommendations per category!</i>
            </CCol>

            <CCol col="1" >  
              <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                <CButton v-on:click="addActionItemtoReport()" class="float-right" size="sm" color="success" :disabled="true"> 
                  <CIcon name="cil-plus"/>
                </CButton>              
                <div class="custom-tooltip custom-tooltip-left" v-show="showTooltip">
                  Currently not supported.
                </div>
                </div>  
              <!-- <CButton v-on:click="addActionItemtoReport()" class="float-right" size="sm" color="success" :disabled="checkActionItemLength()"> 
                <CIcon name="cil-plus"/>
              </CButton> -->



            </CCol>
          </CRow>

        </CCardHeader>
        <CCardBody>          
          <template>
            <CRow>                   
              <CCol col="12" >
            <CDataTable
            hover
            striped
            sorter
            :key="reportActionItemsKey"
            column-filter            
            :items="report.report_action_items"
            :fields="reportActionItemsTableFields"
            :items-per-page="12"     
             
            >            
            
            <td slot="action_item_type" slot-scope="{item}">
              {{ niceActionItemCategory(item.action_item_type) }}            
            </td>             
            <td slot="action_item_effort" slot-scope="{item}">
              {{ item.action_item_effort.toUpperCase()}}            
            </td>             
            <td slot="action_item_impact" slot-scope="{item}">
              {{ item.action_item_impact.toUpperCase() }}            
            </td> 
            <td slot="action_item_open" slot-scope="{item}" >
              <CButton v-on:click="showActionItem(item)" :id="item.action_item_id" block color="dark" style="display: flex;">
                <span class="material-icons md-18">search</span>
              </CButton>
              </td>     
              <td slot="action_item_delete" slot-scope="{item}">
                <CButton v-on:click="deleteActionItemFromReport(item)" :id="item.action_item_id" block color="danger"  style="display: flex;"> <span class="material-icons md-18">delete</span></CButton>            
              </td>              
            </CDataTable>      
              </CCol>
            </CRow>      
          </template>                 
        </CCardBody>
      </CCard>      
    </CCol>
    <CCol xs="12" sm="12" md="12" lg="8" xl="8">
      <CCard>
        <CCardHeader>
          <b>Last Report</b>
        </CCardHeader>
        <CCardBody >
      <template>
                <CRow>
                  <CCol col="3">
                    <CSelect
                      label=""
                      v-model="report.report_status"
                      :value.sync="report.report_status"
                      :options="statusList"
                      disabled
                    >
                      <template #prepend-content><b>Status</b></template>
                    </CSelect>      
                  </CCol>
                    <CCol col="8">
                      <CInput
                        placeholder="No report available"
                        v-model="report.report_last_url"
                        disabled
                      >
                        <template #prepend-content><CIcon name="cil-link"/></template>
                      </CInput>
                    </CCol>
                    <CCol col="1">
                    <CButton  
                        :id="report.report_last_url"
                        :href="report.report_last_url"
                        :disabled="checkReportUrl()"
                        target="_blank"
                        color="info"
                        style="display: inline-grid;">
                        <span class="material-icons md-18">link</span>
                      </CButton>
                    </CCol>
                </CRow>
              </template>              
            </CCardBody>
        </CCard>    
      <CCard>
        <CCardHeader>
          <b>Report History</b>
          <CButton class="float-right col-sm-2" variant="outline" v-on:click="generateReport();" color="info" v-c-tooltip="{ content: 'Generates a new Report' }">Generate new Report</CButton>
        </CCardHeader>
        <CCardBody >
          <CDataTable
            hover
            striped
            sorter            
            column-filter        
            cleaner
            :sorterValue="{ column: 'report_status', asc: 'desc' }"
            :items="report.report_history"
            :fields="reportTableFields"
            :items-per-page="10"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            @page-change="pageChange"
            :loading=loading
          >            
          
              <td slot="report_created" slot-scope="{item}" >                  
                {{ convertDate(item.report_created) }}
              </td> 
              <td slot="report_valid_until" slot-scope="{item}" >                  
                {{ convertDate(item.report_valid_until) }}
              </td>                          
              <td slot="report_url" slot-scope="{item}"  v-c-tooltip="{ content: item.report_url}">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.report_url }}
                </span>
              </td> 
              <td slot="report_error" slot-scope="{item}"  v-c-tooltip="{ content: item.report_error}">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.report_error }}
                </span>
              </td> 
              <td slot="report_open_url" slot-scope="{item}" v-c-tooltip="{ content: 'Open the Report in a new tab.' }">
                <CButton                     
                  :id="item.report_url"
                  :href="item.report_url"
                  :disabled="item.report_url.length < 1"
                  target="_blank"
                  color="info"
                  style="display: inline-grid;">
                  <span class="material-icons md-18">link</span>
                </CButton>
              </td>     
              <td slot="report_delete" slot-scope="{item}" v-c-tooltip="{ content: 'Delete the generated Report.' }">
                <CButton v-on:click="deleteReport(item)" :id="item.report_id" block color="danger"  style="display: flex;" :disabled="true"> <span class="material-icons md-18">delete</span></CButton>
              
              </td>                                
          </CDataTable>
        </CCardBody>
        </CCard>    
    </CCol>    
  </CRow>
  <CModal
      :title="viewActionItem.action_item_name"
      color="dark"
      :show.sync="viewActionItemModal"
    >

        <CCard>
          <CCardHeader>
            <strong>Action Item</strong>
          </CCardHeader>
          <CCardBody>

            <CRow>
              <CCol col="6">
                <CInput
                  label="Name"
                  placeholder="Action Item Name"
                  :key="viewActionItem.action_item_id"
                  :value.sync="viewActionItem.action_item_name"
                  :invalid-feedback=invalidFeedbackActionItemName
                  description="The Name can't be changed."
                  required
                  :is-valid="!$v.viewActionItem.action_item_name.$invalid"  
                  :disabled="addNewActionItem === false"
                  >
                </CInput>                
              </CCol>
              <CCol col="6">
                <CSelect
                      label="Category"
                      v-model="viewActionItem.action_item_type"
                      :value.sync="viewActionItem.action_item_type"
                      placeholder="Select the category..."
                      :options="actionItemTypeOptions"
                      :disabled="addNewActionItem === false"
                      description="The Category can't be changed."
                      :is-valid="!$v.viewActionItem.action_item_type.$invalid"  
                      :invalid-feedback=invalidFeedbackActionItemCategory
                      required
                    >
                </CSelect>    
              </CCol>
            </CRow>              
            <CRow>
              <CCol col="12">
               <CTextarea
                label="Description"
                v-model="viewActionItem.action_item_explanation"
                :value.sync="viewActionItem.action_item_explanation"       
                :is-valid="!$v.viewActionItem.action_item_explanation.$invalid"  
                invalid-feedback="The Description can't be empty"
                >                
                </CTextarea>            
              </CCol>
            </CRow>             
            <CRow>
              <CCol col="6">
                <CSelect
                  label="Effort"
                  v-model="viewActionItem.action_item_effort"
                  :value.sync="viewActionItem.action_item_effort"
                  :options=effortImpactList
                  placeholder="Select..."
                  description="The Effort can be changed."
                  :is-valid="!$v.viewActionItem.action_item_effort.$invalid"  
                  :invalid-feedback=invalidFeedbackActionItemEffortImpact
                  >
                </CSelect>  
              </CCol>
              <CCol col="6">
                <CSelect
                      label="Impact"
                      v-model="viewActionItem.action_item_impact"
                      :value.sync="viewActionItem.action_item_impact"
                      :options=effortImpactList
                      placeholder="Select..."
                      description="The Impact can be changed."
                      :is-valid="!$v.viewActionItem.action_item_impact.$invalid"  
                      :invalid-feedback=invalidFeedbackActionItemEffortImpact
                      >
                </CSelect>    
              </CCol>
            </CRow> 

            <CRow>
              <CCol col="12">
                <p class="pl-1">
                  <CInputCheckbox
                    label="Link to Finding/Improvement?"
                    name="Link to Finding/Improvement?"
                    :checked="isLinkedToFinding"
                    key="isLinkedToFinding"                    
                    @update:checked=isLinkedToFindingChecked                    
                  />
                </p>

              </CCol>
              <CCol col="12" v-show=isLinkedToFinding>
                  <p>       
           
                <multiselect
                    :value="viewActionItem.action_item_link"
                    :options="findingsImprovementList"
                    track-by="link_id"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    label="link_name" 
                    :hideSelected="false"
                    placeholder="Select an improvement..."
                    :loading="actionItemLinkLoading"
                    @select="actionItemLinkedSelected"
                    @remove="actionItemLinkedRemoved" 
                  >
                   </multiselect>
                  <template v-if="$v.viewActionItem.action_item_link.$invalid">
                    <span class="text-danger small" v-if="!$v.viewActionItem.action_item_link.$required">This value is required</span>
                  </template>       
                </p>
              </CCol>
            </CRow>              

          </CCardBody> 
        </CCard>     
        <CCard v-show=isLinkedToFinding>
          <CCardHeader>
            <strong>Findings/Improvements</strong>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="!viewActionItem.action_item_link">
              <CCol col="12">
                <center><p><b>Nothing selected!</b></p></center>
              </CCol>
            </CRow>        

            <CRow v-if="viewActionItem.action_item_link">
              <CCol col="6">
                <CInput
                  label="Name"
                  placeholder="Linked Item Name"
                  :key="viewActionItem.action_item_link.link_id"
                  :value.sync="viewActionItem.action_item_link.link_name"       
                  :disabled=true
                  >
                </CInput>                
              </CCol>              
              <CCol col="12">
                <CTextarea
                label="Description"     
                :value.sync="viewActionItem.action_item_link.link_desc"       
                :disabled=true
                
                >
                </CTextarea>  
              </CCol>

              <CCol col="10">
                    <CInput                      
                      placeholder="No URL available"
                      v-model="viewActionItem.action_item_link.link_url"
                      disabled
                    >
                      <template #prepend-content><CIcon name="cil-link"/></template>
                    </CInput>
                  </CCol>
                  <CCol col="1">
                  <CButton  
                      :id="viewActionItem.action_item_link.link_url"
                      :href="viewActionItem.action_item_link.link_url"
                      :disabled="viewActionItem.action_item_link.link_url === undefined"
                      target="_blank"
                      color="info"
                      style="display: inline-grid;">
                      <span class="material-icons md-18">link</span>
                    </CButton>
                  </CCol>
            </CRow>           
          </CCardBody>
        </CCard>       
                  
      <div slot="footer" class="w-100">
          <CButton @click="viewActionItemModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-left" >Close</CButton>  
          <CButton v-on:click="saveActionItem(viewActionItem);"  color="success" class="ml-1 mr-1 col-sm-2 float-right" :disabled="$v.viewActionItem.$invalid" >Save</CButton>  
      </div>
    </CModal>       
  </CCol>  
</template>


<script>
import Multiselect from 'vue-multiselect'
import ImprovementService from '@/services/improvement.services';
import ReportService from '@/services/report.services';
import CustomerService from '@/services/customer.services';
import WorkloadService from '@/services/workload.services';
import LenseService from '@/services/lense.services';
import ItemService from '@/services/item.services';
import moment from 'moment';
import { required, requiredIf, sameAs, minLength, maxLength, integer, helpers } from 'vuelidate/lib/validators'


export default {
  components: {
    Multiselect
  },  
  data() {
    return {
      viewActionItemModal: false,
      viewActionItem: {},
      viewActionItemSource: {},
      addNewActionItem: false,
      isLinkedToFinding: false,
      actionItemLinkLoading: false,
      invalidFeedbackActionItemName: "",
      invalidFeedbackActionItemCategory: "",
      invalidFeedbackActionItemEffortImpact: "",
      reportActionItemsKey: 0,
      actionItemTypeOptions: [ { value: 'long_term', label: 'Long Term', disabled: false }, { value: 'medium_term', label: 'Medium Term', disabled: false }, { value: 'short_term', label: 'Short Term', disabled: false },],
      selectedCustomer: {},
      report: {},
      statusList: [],
      workloads: [],
      customers: [],
      saveDisabled: true,
      lenses: {},
      selectedRemediationItems: [],
      remediationItems: [],
      selectedWorkload: {},
      reportSaved: false,
      actionItemSaved: false,
      remediationItemsLoading: true,
      workloadsLoading: true,
      reportTotalEffort: 0.0,
      reportGenerateMsg: false,
      reportFailedMsg: false,
      reportGeneratedMsg: false,
      reportSaveFailedMsg: false,
      loading: true,
      reportLastUrl: "",
      effortImpactList: [ { label: "Low", value: 'low' }, { label: "Medium", value: 'medium' }, { label: "High", value: 'high' }   ],
      findingsImprovementList: [ ], // TODO: Change to API
      showTooltip: false,
  
      reportTableFields: [
        { key: 'report_status', label: 'Status', _style:'width:10%' },
        { key: 'report_created', label: 'Created', _style:'width:20%', filter: false   },
        { key: 'report_valid_until', label: 'Valid Until', _style:'width:20%', filter: false,  },
        { key: 'report_url', label: 'URL', _style:'width:25%', filter: true, sorter: true },
        { key: 'report_error', label: 'Message', _style:'width:25%' , sorter: false, filter: false },
        { key: 'report_open_url', label: '',  _style: 'width:1%', sorter: false, filter: false },
        { key: 'report_delete', label: '',  _style: 'width:1%', sorter: false, filter: false }
      ],   
      reportActionItemsTableFields: [
        { key: 'action_item_name', label: 'Name', _style:'width:20%', filter: false, sorter: false },
        { key: 'action_item_type', label: 'Category', _style:'width:20%', filter: false, sorter: true   },
        { key: 'action_item_effort', label: 'Effort', _style:'width:10%', filter: false, sorter: false },
        { key: 'action_item_impact', label: 'Impact', _style:'width:10%', filter: false, sorter: false },
        { key: 'action_item_open', label: '',  _style: 'width:1%', sorter: false, filter: false },
        { key: 'action_item_delete', label: '',  _style: 'width:1%', sorter: false, filter: false }
      ],   
      activePage: 1,

    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    viewActionItem: {
      action_item_name: {
        required,
        minLength: minLength(4),
        $autoDirty: true
      },
      action_item_type: {
        required,        
        $autoDirty: true
      },
      action_item_explanation: {
        required,        
        minLength: minLength(5)        
      },
      action_item_effort: {
        required,
        isEffort (value) {
          if (this.effortImpactList.find(item => item.value === value)) {
            return true 
          } else { 
            return false
          }
        }        
      },
      action_item_impact: {
        required,       
        isImpact (value) {
          if (this.effortImpactList.find(item => item.value === value)) {
            return true 
          } else { 
            return false
          }
        }                 
      },
      action_item_link: {      
        required: requiredIf(function(){return this.isLinkedToFinding}),
        link_id: {
          required: requiredIf(function(){return this.isLinkedToFinding}),
        }
      }
    }
  },
  methods: {      
    checkReportUrl() {
      if(typeof(this.report.report_last_url) === `undefined`){
        return true;
      }
      return false;
    },
    goBack() {
      this.$router.push({path: '/reports'})
    },       
    convertDate(date) {  
      return moment(date).format('DD.MM.YYYY - H:mm ')
    },    
    pageChange (val) {
      this.$router.push({ query: { page: val }});
    },    
    isLinkedToFindingChecked(value) {
      this.isLinkedToFinding = value;
      this.viewActionItem.action_item_link = null;
    },    
    showActionItem (actionItem) {
      this.addNewActionItem = false;
      this.viewActionItemSource = actionItem;
      this.viewActionItem = JSON.parse(JSON.stringify(actionItem))

      if (this.viewActionItem.action_item_link !== undefined && this.viewActionItem.action_item_link !== null && Object.keys(this.viewActionItem.action_item_link).length > 0){
        this.isLinkedToFinding = true
      } else {
        this.viewActionItem.action_item_link = null
        this.isLinkedToFinding = false
      }
      this.viewActionItemModal = true;
    },
    actionItemLinkedRemoved() {
      this.viewActionItem.action_item_link = null
    },
    actionItemLinkedSelected(event) {
      this.viewActionItem.action_item_link = {...event}
    },    
    saveActionItem(actionItem) {      
      var tmp = {}          
      tmp["action_item_id"] = actionItem.action_item_id

      const index = this.report.report_action_items.findIndex(tmp => tmp.action_item_id === actionItem.action_item_id);

      if (index > -1) {
        this.report.report_action_items [index] = actionItem
      } else {
        this.report.report_action_items.push(actionItem)
      }   
      this.reportActionItemsKey = this.reportActionItemsKey + 1
      this.inputChanged()
      this.actionItemSaved = true;
      this.viewActionItemModal = false;
    },
    checkActionItemLength() {
      if (this.report.report_action_items !== undefined && this.report.report_action_items.length >= 12) return true

      var short_term = 0
      var medium_term = 0
      var long_term = 0
      for (let index in this.report.report_action_items ) {
        if (this.report.report_action_items [index]['action_item_type'] === 'short_term') short_term=short_term+1
        if (this.report.report_action_items [index]['action_item_type'] === 'medium_term') medium_term=medium_term+1
        if (this.report.report_action_items [index]['action_item_type'] === 'long_term') long_term=long_term+1
      }
      if (short_term >= 3) { 
        this.actionItemTypeOptions.find(element => element.value === "short_term").disabled = true
      } else {
        this.actionItemTypeOptions.find(element => element.value === "short_term").disabled = false
      }
      if (medium_term >= 3) { 
        this.actionItemTypeOptions.find(element => element.value === "medium_term").disabled = true
      } else { 
        this.actionItemTypeOptions.find(element => element.value === "medium_term").disabled = false
      }
      if (long_term >= 3) { 
        this.actionItemTypeOptions.find(element => element.value === "long_term").disabled = true
      } else {
        this.actionItemTypeOptions.find(element => element.value === "long_term").disabled = false
      }

      return false
    },
    addActionItemtoReport() {
      this.isLinkedToFinding = false;
      this.addNewActionItem = true;   
      this.viewActionItem = {
          "action_item_id": null,
          "action_item_type": null,
          "action_item_name": null,
          "action_item_effort": null,
          "action_item_impact": null,
          "action_item_explanation": null,
          "action_item_link": null
      };
      this.viewActionItemModal = true;
    },
    niceActionItemCategory(actionItemType) {
      if (actionItemType === "short_term") {
        return "Short Term"
      } else if (actionItemType === "medium_term") {
        return "Medium Term"
      } else if (actionItemType === "long_term") {
        return "Long Term"
      }
    },    
    deleteActionItemFromReport(actionItem) {
      const index = this.report.report_action_items.indexOf(actionItem);
      if (index > -1) {
        this.report.report_action_items.splice(index, 1);
        this.inputChanged()
      }      
    },
    selectRemediationItem(selectedOption, id) {
      var tmp = {}
      tmp['id'] = selectedOption.id
      tmp['name'] = selectedOption.name
      tmp['lense'] = selectedOption.lense
      this.reportTotalEffort = +this.reportTotalEffort + +selectedOption.effort
      this.selectedRemediationItems.push(tmp)    
    },
    removeRemediationItem(removedOption, id) {
      const index = this.selectedRemediationItems.indexOf(removedOption);
      if (index > -1) {      
         if (this.selectedRemediationItems.length > 1) {
          this.reportTotalEffort = +this.reportTotalEffort - +removedOption.effort;
         } else {
           this.reportTotalEffort = 0.0;
         }
         this.selectedRemediationItems.splice(index, 1);
      }     
    },
    inputChanged() {
      if(this.saveDisabled) {
        this.saveDisabled=false;
      }
    },    
    reportNameValidator (val) {
        var valid = false;
        
        const regex = new RegExp('^([a-zA-Z0-9-_\\.&!#=()\\s]){4,}$');
        valid = val ? regex.test(val)  : false;
        
        if(!this.saveDisabled) {
          this.saveDisabled = !valid;
        }

        return valid
    },       
    async processReportData() {
     
      var workload = this.findWorkload(this.report.report_workload_id);
      
      this.selectedWorkload = {
        "id": this.report.report_workload_id,
        "label": workload.label
      };

      this.selectedCustomer = this.findCustomer(this.report.report_customer_id)
      for (var key in this.report.report_remediation_items){

        ItemService.getItem(this.report.report_remediation_items[key]).then(
          response => { 
             var remediationItem = response;
              var tmp = {};

              tmp["lense"] = this.lenses[remediationItem.item_lense];
              tmp['id'] = remediationItem.item_id;
              tmp['effort'] = remediationItem.item_effort;
              // tmp['name'] = remediationItem.item_title;
              tmp['name'] = remediationItem.item_title + " (" + remediationItem.item_effort + " FTE)";

              this.reportTotalEffort = this.reportTotalEffort + remediationItem.item_effort;

              this.selectedRemediationItems.push(tmp)
          },
          error => {
            console.log(error)   
          }      
        )             
      }
      this.remediationItemsLoading = false;  
      this.loading = false;
    },
  
    findCustomer(id) {
      var tmp = {}          
      tmp["customer_id"] = id

      const index = this.customers.findIndex(tmp => tmp.customer_id === id); 

      if (index > -1) {
        return this.customers[index]
      } else {
        return {}
      }             
    },
    findWorkload(id) {
      var tmp = {}          
      tmp["id"] = id
      const index = this.workloads.findIndex(tmp => tmp.id === id); 

      if (index > -1) {
        return this.workloads[index]
      } else {
        return {}
      }             
    }, 
    async generateReport() {

      ReportService.generateReport(
        this.$route.params.id, 
        this.selectedCustomer.customer_id, 
        this.selectedCustomer.customer_name,
        this.selectedWorkload.id,
        ).then(
          response => { 
            this.reportGenerateMsg = true;
          },
          error => {   
            this.reportFailedMsg = true;
            console.log(error);  
          }      
      )
    },        
    async saveReport() {

      var tmp_remediation_items = []
      for (var key in this.selectedRemediationItems) {
        tmp_remediation_items.push(this.selectedRemediationItems[key].id)
      }          
      ReportService.saveReport(
        this.$route.params.id, 
        this.report.report_name, 
        this.selectedWorkload.id, 
        tmp_remediation_items,
        this.report.report_action_items 
        ).then(
          response => { 
            this.reportSaved = true;
            this.inputChanged = false;
          },
          error => {
            this.reportSaveFailedMsg = true;
            console.log(error);
          }      
      )
    },                 
  },
  created() { },
  async mounted() { 
    await ReportService.getReport(this.$route.params.id).then(
      response => { 
        this.report = response;
      },
      error => {   
        console.log(error)   
      }       
    );
    await ReportService.getReportStatusList().then(
      response => { 
        this.statusList = response;
      },
      error => {   
        console.log(error)   
      }  
    )
    await WorkloadService.getWorkloads().then(
      response => { 
        var tmp_workloads = response;
        for (var key in tmp_workloads) {
          var tmp = {}
          tmp['id'] = tmp_workloads[key].WorkloadId
          tmp['label'] = tmp_workloads[key].WorkloadName
          this.workloads.push(tmp)
        };
        this.workloadsLoading = false;
      },
      error => {   
        console.log(error)   
      }      
    )
    await LenseService.getLenses().then(
      response => { 
        this.lenses = response;
      },
      error => {   
        console.log(error)   
      }      
    )   
    // TODO: Get customer instead?
    await CustomerService.getCustomers().then(
      response => { 
        this.customers = response;
      },
      error => {   
        console.log(error)   
      }      
    )
    await ItemService.getItems().then(
      response => { 
        
        for (var key in response){
          
          var tmp = {}          
          tmp["lense"] = this.lenses[response[key].item_lense]
          tmp['data'] = []

          const index = this.remediationItems.findIndex(tmp => tmp.lense === this.lenses[response[key].item_lense]); 
        
          if (index > -1) {
            this.remediationItems[index]['data'].push( {"id": response[key].item_id, "effort": response[key].item_effort, "name": response[key].item_title + " (" + response[key].item_effort + " FTE)"} )
          } else {
            tmp['data'].push( {"id": response[key].item_id, "effort": response[key].item_effort,  "name": response[key].item_title + " (" + response[key].item_effort + " FTE)" } )
            this.remediationItems.push(tmp)
          }                
        }
      },
      error => {   
        console.log(error)   
      }      
    )    
    await this.processReportData();  
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>